// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import Row from '../components/grid/row';
import Column from '../components/grid/column';
import Link from '../components/link';
import DefaultLayout from '../layouts/default';
import Container from '../components/grid/container';

type Props = {
  data: {
    pages: {
      edges: Array<{
        node: {
          title: string,
          slug: string,
          created: string,
          _rawContent: Array<any>,
        },
      }>,
    },
    pages_: {
      edges: Array<{
        node: {
          childMarkdownRemark: {
            excerpt: string,
            wordCount: {
              words: number,
            },
            timeToRead: number,
            fields: {
              slug: string,
            },
            frontmatter: {
              title: string,
              date: string,
            },
          },
        },
      }>,
    },
  },
};

export default ({ data: { pages: { edges } } }: Props) => (
  <DefaultLayout slim>
    <Container>
      <Row spaced>
        <Column center>
          <h1>Blog Posts</h1>
        </Column>
      </Row>
      {edges
        .sort((a, b) => (a.node.created > b.node.created ? -1 : 1))
        .map(({ node: { title, slug, _rawContent } }) => (
          <Row spaced key={slug}>
            <Column>
              <Link to={`/blog/${slug}`}>
                <h2 className={'h3'}>
                  {title}
                </h2>
              </Link>
              <BlockContent
                blocks={_rawContent[0]}
                serializers={{}}
              />
              <Link to={`/blog/${slug}`}>
                Read More &raquo;
              </Link>
            </Column>
          </Row>
        ))}
    </Container>
  </DefaultLayout>
);

export const pageQuery = graphql`
    query {
        pages: allSanityBlogPost {
            edges {
                node {
                    title
                    slug
                    created
                    _rawContent(resolveReferences: {maxDepth: 5})
                }
            }
        }
        pages_: allFile(filter: {sourceInstanceName: {eq: "blog-pages"}, extension: {eq: "md"}}) {
            edges {
                node {
                    childMarkdownRemark {
                        excerpt
                        timeToRead
                        wordCount {
                            words
                        }
                        fields {
                            slug
                        }
                        frontmatter {
                            slug
                            title
                            date
                        }
                    }
                }
            }
        }
    }
`;
